import { CategoryDependencies, Customize } from 'graphql/types'
import { Product, ProductCategory, ProductType, SelectedProduct } from '../../graphql/types'
import { Page, SelectedProductCategory } from '../../store/GeneralState/GeneralState.reducer'

export const updatePagesList = (
    availableProductCategories: ProductCategory[],
    selectedProductCategories: SelectedProductCategory[],
    crossSoldProductCategories: string[],
    initialPageList: Page[],
    B2B: boolean,
    customize?: Customize,
): Page[] => {
    const pagesToReturn = [...initialPageList]

    // remove the product category selection page if it is not enabled
    if (customize && customize.productCategoriesConfiguration.enablePage) {
        pagesToReturn.push({ path: '/Produktkategorieauswahl' })
    }

    // add the product pages and the depending option pages
    let displayTelephoneProduct = false
    selectedProductCategories.forEach((selected: SelectedProductCategory) => {
        if (crossSoldProductCategories.includes(selected.id)) {
            pagesToReturn.push({ path: '/Querverkauf/' + selected.id })
        }
        pagesToReturn.push({ path: '/Produktauswahl/' + selected.id })
        const selectedProductCategoryFromData = availableProductCategories.find(
            (productCategory: ProductCategory) => productCategory.id === selected.id,
        )
        if (selected.selectedProduct && selectedProductCategoryFromData) {
            const selectedProductFromData = selectedProductCategoryFromData.products.find(
                (product: Product): boolean => {
                    if (selected.selectedProduct) {
                        return product.id === selected.selectedProduct.id
                    }
                    return false
                },
            )
            if (selectedProductFromData && selectedProductFromData.productTypes.length > 0) {
                selectedProductFromData.productTypes.forEach((productType, index) => {
                    if (selected.selectedProduct && productType.optional) {
                        pagesToReturn.push({
                            path: '/Produkttypauswahl/' + selected.selectedProduct.id + '/' + productType.identifier,
                        })
                    }
                    if (
                        selected.selectedProduct &&
                        (!productType.optional ||
                            selected.selectedProduct.productTypes.findIndex((v) => v.id === productType.id) !== -1)
                    ) {
                        let hasHardwareOptions = false
                        let hasServiceOptions = false
                        for (const category of productType.category) {
                            if (category.type === 'service') {
                                hasServiceOptions = true
                            } else if (
                                category.type === 'hardware' &&
                                hardwareIsDisplayed(selected.selectedProduct, productType, customize)
                            ) {
                                hasHardwareOptions = true
                            }
                        }

                        if (index === 0) {
                            if (selectedProductFromData.productTypes.length > 1) {
                                if (productType.category.length >= 0) {
                                    if (hasServiceOptions) {
                                        pagesToReturn.push({
                                            path:
                                                '/Produktoptionen/service/' +
                                                selected.selectedProduct.id +
                                                '/' +
                                                productType.identifier,
                                        })
                                    }
                                    if (hasHardwareOptions) {
                                        pagesToReturn.push({
                                            path:
                                                '/Produktoptionen/hardware/' +
                                                selected.selectedProduct.id +
                                                '/' +
                                                productType.identifier,
                                        })
                                    }
                                }
                            } else {
                                if (productType.category.length > 0) {
                                    if (hasServiceOptions) {
                                        pagesToReturn.push({
                                            path:
                                                '/Produktoptionen/service/' +
                                                selected.selectedProduct.id +
                                                '/' +
                                                productType.identifier,
                                        })
                                    }
                                    if (hasHardwareOptions) {
                                        pagesToReturn.push({
                                            path:
                                                '/Produktoptionen/hardware/' +
                                                selected.selectedProduct.id +
                                                '/' +
                                                productType.identifier,
                                        })
                                    }
                                }
                            }
                        } else {
                            if (productType.category.length > 0) {
                                if (hasServiceOptions) {
                                    pagesToReturn.push({
                                        path:
                                            '/Produktoptionen/service/' +
                                            selected.selectedProduct.id +
                                            '/' +
                                            productType.identifier,
                                    })
                                }
                                if (hasHardwareOptions) {
                                    pagesToReturn.push({
                                        path:
                                            '/Produktoptionen/hardware/' +
                                            selected.selectedProduct.id +
                                            '/' +
                                            productType.identifier,
                                    })
                                }
                            }
                        }
                    }

                    if (
                        selected.selectedProduct &&
                        productType.identifier === 'telephone' &&
                        selected.selectedProduct.productTypes.findIndex((v) => v.id === productType.id) !== -1
                    ) {
                        displayTelephoneProduct = true
                    }
                })
            }
        }
    })

    // Cross selling. Go through the customize cross selling rules and check if the selected product categories match the rule.
    if (customize && customize.crossSelling.enabledPage) {
        for (const rule of customize.crossSelling.rules) {
            // get the excluded product from the function
            const excludedProductCategories = getExcludedProductCategories(
                rule.target,
                selectedProductCategories,
                customize.productCategoriesConfiguration.categoryDependencies,
            )
            if (
                availableProductCategories.some((item) => item.id === rule.target) &&
                rule.required.every((item) =>
                    selectedProductCategories.some((productItem) => productItem.id === item),
                ) &&
                !excludedProductCategories.some((item) =>
                    selectedProductCategories.some((productItem) => productItem.id === item),
                )
            ) {
                // add page cross selling
                pagesToReturn.push({ path: '/Querverkauf/' + rule.target })
                break
            }
        }
    }

    // add vzf page
    if (!B2B) {
        pagesToReturn.push({ path: '/VZF' })
        pagesToReturn.push({ path: '/VZFLandingpage' })
    }

    // add contact page if enabled
    if (customize && customize.contactDataViewConfiguration.enablePage) {
        pagesToReturn.push({ path: '/Kontaktdaten' })
    }

    // add telephone page if enabled
    if (customize && customize.telephoneOptions.enablePage && displayTelephoneProduct) {
        pagesToReturn.push({ path: '/FestnetzVertragsOptionen' })
    }

    // add banking page if enabled
    if (customize && customize.bankDetails.enablePage) {
        pagesToReturn.push({ path: '/Zahlungsdetails' })
    }

    // instalation page if enabled
    if (customize && customize.installationDetailsConfiguration.enablePage) {
        pagesToReturn.push({ path: '/Installationsdetails' })
    }

    // voucher page if enabled
    if (customize && customize.voucherConfiguration.enableManualPage) {
        pagesToReturn.push({ path: '/Gutscheineingabe' })
    }

    // desired date page if enabled
    if (customize && customize.installationConfiguration.enablePage) {
        pagesToReturn.push({ path: '/Wunschtermin' })
    }

    // add default pages to the list of pages
    pagesToReturn.push({ path: '/Bestellüberprüfung' })
    pagesToReturn.push({ path: '/VielenDank' })

    // add edit pages if edit function is enabled
    if (customize && customize.orderOverview.isEditable) {
        pagesToReturn.push({ path: '/Bearbeiten' })
        pagesToReturn.push({ path: '/RufnummernmitnahmeBearbeiten' })
    }

    return pagesToReturn
}

// We get all the categories identifier which exclude the given identifier
const getExcludedProductCategories = (
    identifier: string,
    selectedProductCategories: SelectedProductCategory[],
    configurationProductCategories: CategoryDependencies[],
): string[] => {
    const returnArray = new Set<string>()
    for (const config of configurationProductCategories) {
        if (config.id === identifier) {
            config.config.excluded.forEach(returnArray.add, returnArray)
        } else if (config.config.excluded.some((str: string) => str === identifier)) {
            returnArray.add(config.id)
        }
        for (const productCategory of selectedProductCategories) {
            if (config.id === productCategory.id) {
                config.config.excluded.forEach(returnArray.add, returnArray)
            } else if (config.config.excluded.some((str: string) => str === productCategory.id)) {
                returnArray.add(config.id)
            }
        }
    }
    returnArray.add(identifier)
    return Array.from(returnArray)
}

export const checkIfWeShouldAddPortabilityPage = (
    configurationState: Map<string, string | string[]>,
    pageList: Page[],
    customize?: Customize,
): Page[] => {
    const returnedPages: Page[] = [...pageList]
    if (customize) {
        const telephonePathIndex = pageList.findIndex((p) => p.path === '/FestnetzVertragsOptionen')
        const portabilityPathIndex = pageList.findIndex((p) => p.path === '/Rufnummernmitnahme')
        const value = configurationState.get(customize.portabilityConfiguration.showPortability.identifier) as string
        if (portabilityPathIndex === -1 && value === customize.portabilityConfiguration.showPortability.wishValue)
            returnedPages.splice(telephonePathIndex + 1, 0, { path: '/Rufnummernmitnahme' })
        if (portabilityPathIndex !== -1 && value !== customize.portabilityConfiguration.showPortability.wishValue)
            returnedPages.splice(portabilityPathIndex, 1)
    }
    return returnedPages
}

const hardwareIsDisplayed = (
    selectedProduct: SelectedProduct,
    productType: ProductType,
    customize?: Customize,
): boolean => {
    if (customize) {
        for (const hardwareSelection of customize?.globalConfiguration.enableHardwareSelection) {
            if (hardwareSelection === productType.identifier) {
                const spt = selectedProduct.productTypes.find((v) => v.id === productType.id)
                return spt != undefined && spt.optionCategories.length > 0
            }
        }
    }
    return true
}
