import { CategoryDependencies } from 'graphql/types'
import { SelectedProductCategory } from 'store/GeneralState/GeneralState.reducer'

const categoryIdentifiersThatHaveThisCategoryRequired = (
    identifier: string,
    productCategoriesConfiguration: CategoryDependencies[],
): string[] => {
    const returnArray: string[] = productCategoriesConfiguration.map((con: CategoryDependencies) => {
        if (con.config.required.findIndex((str: string) => str === identifier) !== -1) return con.id
        return ''
    })
    return returnArray.filter((str) => str.length > 0)
}

const categoryIdentifiersThatHaveThisCategoryExcluded = (
    identifier: string,
    configurationProductCategories: CategoryDependencies[],
): string[] => {
    const returnArray: string[] = configurationProductCategories.map((con: CategoryDependencies) => {
        if (con.config.excluded.findIndex((str: string) => str === identifier) !== -1) return con.id
        return ''
    })
    return returnArray.filter((str) => str.length > 0)
}

const getSelectedProductCategoryIndex = (
    identifier: string,
    selectedProductCategories: SelectedProductCategory[],
): number => {
    return selectedProductCategories.findIndex((p: SelectedProductCategory) => p.id === identifier)
}

const getProductCategoryConfig = (
    identifier: string,
    productCategoriesConfiguration: CategoryDependencies[],
): CategoryDependencies | undefined => {
    const productCategoryConfigIndex = productCategoriesConfiguration.findIndex(
        (c: CategoryDependencies) => c.id === identifier,
    )
    return productCategoryConfigIndex !== -1 ? productCategoriesConfiguration[productCategoryConfigIndex] : undefined
}

const doesProductCategoryHaveDependency = (
    id: string,
    configurationProductCategories: CategoryDependencies[],
): boolean => {
    return configurationProductCategories.findIndex((c: CategoryDependencies) => c.id === id) !== -1
}

const productCategoriesDependency = (
    stateSelectedProductCategories: SelectedProductCategory[],
    id: string,
    configurationProductCategories?: CategoryDependencies[],
): SelectedProductCategory[] => {
    const selectedProductCategories = [...stateSelectedProductCategories]
    if (configurationProductCategories !== undefined) {
        let selectedProductCategoryIndex = getSelectedProductCategoryIndex(id, selectedProductCategories)
        const hasDependency: boolean = doesProductCategoryHaveDependency(id, configurationProductCategories)
        const categoriesThatRequireThis: string[] = categoryIdentifiersThatHaveThisCategoryRequired(
            id,
            configurationProductCategories,
        )
        const categoriesThatExcludeThis: string[] = categoryIdentifiersThatHaveThisCategoryExcluded(
            id,
            configurationProductCategories,
        )
        const isRequired: boolean = categoriesThatRequireThis.length > 0
        const isExcluded: boolean = categoriesThatExcludeThis.length > 0

        const productCategoryConfig = getProductCategoryConfig(id, configurationProductCategories)
        if (selectedProductCategoryIndex !== -1) {
            // We are going to deselect the product category that triggered the reducer
            if (isRequired) {
                categoriesThatRequireThis.forEach((id: string) => {
                    const index = getSelectedProductCategoryIndex(id, selectedProductCategories)
                    if (index !== -1) selectedProductCategories.splice(index, 1)
                })
            }
            selectedProductCategoryIndex = getSelectedProductCategoryIndex(id, selectedProductCategories)
            selectedProductCategories.splice(selectedProductCategoryIndex, 1)
        } else {
            // We are going to select the product category that triggered the reducer
            if (hasDependency && productCategoryConfig !== undefined) {
                productCategoryConfig.config.required.forEach((identifier: string) => {
                    const requiredProductCategoryIndexInState = getSelectedProductCategoryIndex(
                        identifier,
                        selectedProductCategories,
                    )
                    if (requiredProductCategoryIndexInState === -1)
                        selectedProductCategories.push({
                            id: identifier,
                            selectedProduct: undefined,
                        })
                })
                productCategoryConfig.config.excluded.forEach((identifier: string) => {
                    const excludedProductCategoryIndex = getSelectedProductCategoryIndex(
                        identifier,
                        selectedProductCategories,
                    )
                    if (excludedProductCategoryIndex !== -1)
                        selectedProductCategories.splice(excludedProductCategoryIndex, 1)
                })
            }
            if (isExcluded) {
                categoriesThatExcludeThis.forEach((str: string) => {
                    const targetIndex = getSelectedProductCategoryIndex(str, selectedProductCategories)
                    if (targetIndex !== -1) selectedProductCategories.splice(targetIndex, 1)
                })
            }
            selectedProductCategories.push({
                id: id,
                selectedProduct: undefined,
            })
        }
    }
    return selectedProductCategories
}

export default productCategoriesDependency
