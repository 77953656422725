import { Customize } from 'graphql/types'
import _, { sumBy } from 'lodash'
import { formatCurrency } from 'utils/numberFormat'
import { BasketProps } from '../../components/Basket/Basket'
import { CostsTableProps } from '../../components/CostsTable/CostsTable'
import { ProductTableCategoryProps } from '../../components/ProductTableCategory/ProductTableCategory'
import {
    AccountType,
    BillMode,
    Discount,
    Option,
    OptionCategory,
    Product,
    ProductCategory,
    VoucherCurrency,
    VoucherData,
} from '../../graphql/types'
import { AvailabilityCheckState } from '../../store/AvailabilityCheck/AvailabilityCheck.reducer'
import { BankDetailsState } from '../../store/BankDetails/BankDetails.reducer'
import {
    GeneralState,
    InstallationDetailsState,
    SelectedOptionCategory,
    SelectedProductCategory,
} from '../../store/GeneralState/GeneralState.reducer'

interface MonthlyPaymentsData {
    startMonth: number
    endMonth: number
    value: number
}

interface UnlimitedVoucherPriceCalculation {
    oldPrice: number | undefined
    newPrice: number
    description: string[] | undefined
}

export const getSelectedProductCategoryFromData = (
    productCategories: ProductCategory[],
    id: string,
): ProductCategory | undefined => {
    return productCategories.find((availablePC: ProductCategory) => availablePC.id === id)
}

const calculateAssociatedVouchers = (associatedVoucher: VoucherData[], price: number, month: number): number => {
    let newPrice = price
    for (const voucher of associatedVoucher) {
        if (
            ((voucher.value.discountType === 'oneTime' && month === 0) || month < voucher.value.month) &&
            voucher.type !== 'credit'
        ) {
            if (voucher.value.currency === VoucherCurrency.EURO) {
                newPrice = newPrice - voucher.value.value
            } else {
                newPrice -= (newPrice * voucher.value.value) / 100
            }
        }
    }
    if (newPrice < 0) {
        return 0
    }

    return newPrice
}

const getAssociatedMonthOneTimeVouchers = (
    voucherList: VoucherData[],
    processUniqueFeeList: string[],
    id: string,
): VoucherData[] => {
    const associatedVouchers: VoucherData[] = []
    for (const voucher of voucherList) {
        if (voucher.value.discountType === 'unlimited') continue
        const index = voucher.products.findIndex((p) => p.articleId === id)
        if (index !== -1 && processUniqueFeeList.findIndex((e) => e === voucher.id) === -1) {
            associatedVouchers.push(voucher)
            processUniqueFeeList.push(voucher.id)
        }
    }
    return associatedVouchers
}

const getAssociatedUnlimitedVouchers = (
    voucherList: VoucherData[],
    processUniqueFeeList: string[],
    id: string,
): VoucherData[] => {
    const associatedVouchers: VoucherData[] = []
    for (const voucher of voucherList) {
        if (voucher.value.discountType !== 'unlimited') continue
        const index = voucher.products.findIndex((p) => p.articleId === id)
        if (index !== -1 && processUniqueFeeList.findIndex((e) => e === voucher.id) === -1) {
            associatedVouchers.push(voucher)
            processUniqueFeeList.push(voucher.id)
        }
    }
    return associatedVouchers
}

const caluclateUnlimitedVouchers = (
    voucherList: VoucherData[],
    processUniqueFeeList: string[],
    id: string,
    price: number,
): UnlimitedVoucherPriceCalculation => {
    const unlimitedVoucherPriceCalculation: UnlimitedVoucherPriceCalculation = {
        newPrice: price,
        description: undefined,
        oldPrice: undefined,
    }
    const unlimitedVoucher = getAssociatedUnlimitedVouchers(voucherList, processUniqueFeeList, id)
    for (const voucher of unlimitedVoucher) {
        unlimitedVoucherPriceCalculation.oldPrice = price
        if (unlimitedVoucherPriceCalculation.description === undefined)
            unlimitedVoucherPriceCalculation.description = []
        unlimitedVoucherPriceCalculation.description.push(voucher.infoText)
        if (voucher.value.currency === VoucherCurrency.EURO) {
            unlimitedVoucherPriceCalculation.newPrice = unlimitedVoucherPriceCalculation.newPrice - voucher.value.value
        } else {
            unlimitedVoucherPriceCalculation.newPrice =
                unlimitedVoucherPriceCalculation.newPrice -
                (unlimitedVoucherPriceCalculation.newPrice * voucher.value.value) / 100
        }
    }

    return unlimitedVoucherPriceCalculation
}

const caluclatePriceForBasket = (
    title: string,
    price: number,
    billMode: BillMode,
    discounts: Discount[],
    monthlyIncrementsMap: MonthlyPaymentsData[],
    costTableToReturn: CostsTableProps,
    voucherList: VoucherData[],
    b2b: boolean,
    optionsMultipleSelectCounter: number,
) => {
    const monthlyPrice = price * optionsMultipleSelectCounter
    let productPriceWithDiscount = price * optionsMultipleSelectCounter

    //calculate the monthly increment for the discount need to inlude  the voucher?
    let maxEndMonth = -1
    discounts.forEach((discount) => {
        const discountPrice = b2b ? discount.priceNet : discount.gross
        if (discount.billMode === BillMode.ONE_TIME) {
            productPriceWithDiscount = productPriceWithDiscount - discountPrice * optionsMultipleSelectCounter
            costTableToReturn.totalDiscounts.sum +=
                productPriceWithDiscount + discountPrice * optionsMultipleSelectCounter
            costTableToReturn.totalDiscounts.discounts.push({
                name: title,
                oldPrice: 0, //TODO: delete that field
                newPrice: discountPrice * optionsMultipleSelectCounter,
            })
        } else if (discount.billMode === BillMode.RECURRING_MONTHLY) {
            discount.monthlyDiscounts.forEach((monthlyDiscount) => {
                if (monthlyDiscount.initMonth === 0) {
                    productPriceWithDiscount -=
                        (b2b ? monthlyDiscount.priceNet : monthlyDiscount.gross) * optionsMultipleSelectCounter
                }
                const monthlyDiscountPrice =
                    monthlyPrice -
                    (b2b ? monthlyDiscount.priceNet : monthlyDiscount.gross) * optionsMultipleSelectCounter
                monthlyIncrementsMap.push({
                    startMonth: monthlyDiscount.initMonth,
                    endMonth: monthlyDiscount.endMonth,
                    value: calculateAssociatedVouchers(voucherList, monthlyDiscountPrice, 0),
                })

                if (maxEndMonth < monthlyDiscount.endMonth) {
                    maxEndMonth = monthlyDiscount.endMonth
                }

                const sum = price * optionsMultipleSelectCounter - monthlyDiscountPrice
                const sumMonth = sum * maxEndMonth
                costTableToReturn.totalDiscounts.sum += sumMonth
                costTableToReturn.totalDiscounts.discounts.push({
                    name: title + ' (' + maxEndMonth + ' * ' + formatCurrency(sum) + ')',
                    oldPrice: 0, //TODO: delete that field
                    newPrice: sumMonth,
                })
            })
        }
    })
    //calculate the monthly increment for the voucher+
    let maxEndMonthVoucher = -1
    voucherList.forEach((voucher) => {
        if (voucher.value.discountType === 'oneTime' && maxEndMonthVoucher < 0) {
            maxEndMonthVoucher = 0
        } else if (voucher.value.month > maxEndMonthVoucher) {
            maxEndMonthVoucher = voucher.value.month
        }

        if (voucher.type !== 'credit') {
            let maxEndMonth = 1
            let voucherName = voucher.name
            if (voucher.value.discountType === 'severalMonths') {
                maxEndMonth = voucher.value.month
                voucherName = voucher.name + ' (' + maxEndMonth + ' * '
            }
            let newPrice = 0
            if (voucher.value.currency === VoucherCurrency.EURO) {
                newPrice = productPriceWithDiscount - voucher.value.value
                if (newPrice < 0) {
                    newPrice = 0
                }
                const sum = productPriceWithDiscount - newPrice
                const sumMonth = sum * maxEndMonth

                if (voucher.value.discountType === 'severalMonths') {
                    voucherName += formatCurrency(sum) + ')'
                }

                costTableToReturn.totalDiscounts.sum += sumMonth
                costTableToReturn.totalDiscounts.discounts.push({
                    name: voucherName,
                    oldPrice: 0, //TODO: delete that field
                    newPrice: sumMonth,
                })
            } else {
                newPrice = (productPriceWithDiscount * voucher.value.value) / 100
                const newPriceMonth = newPrice * maxEndMonth

                if (voucher.value.discountType === 'severalMonths') {
                    voucherName += formatCurrency(newPrice) + ')'
                }

                costTableToReturn.totalDiscounts.sum += newPriceMonth
                costTableToReturn.totalDiscounts.discounts.push({
                    name: voucherName,
                    oldPrice: 0, //TODO: delete that field
                    newPrice: newPriceMonth,
                })
            }
        }
    })
    while (maxEndMonth < maxEndMonthVoucher) {
        monthlyIncrementsMap.push({
            startMonth: maxEndMonth,
            endMonth: maxEndMonth + 1,
            value: calculateAssociatedVouchers(voucherList, monthlyPrice, maxEndMonth),
        })
        maxEndMonth++
    }

    //calculate end price with the correct price. should include the discount and voucher
    if (billMode === BillMode.RECURRING_MONTHLY) {
        // Monthly Costs for Products
        costTableToReturn.monthlyCost += calculateAssociatedVouchers(voucherList, productPriceWithDiscount, 0)

        monthlyIncrementsMap.push({
            startMonth: maxEndMonth,
            endMonth: -1,
            value: monthlyPrice,
        })
    } else if (billMode === BillMode.ONE_TIME) {
        // One Time Costs for Products
        costTableToReturn.oneTimeCost += calculateAssociatedVouchers(voucherList, productPriceWithDiscount, 0)
    }
}

export const formatSelectedProductCategory = (
    customizeJsData: Customize | undefined,
    bankDetailsState: BankDetailsState,
    optionsMultipleSelect: Map<string, number>,
    configuration: Map<string, string | string[]>,
    availableProductCategories: ProductCategory[],
    selectedProductCategory: SelectedProductCategory,
    processUniqueFeeList: string[],
    processUniqueVoucherList: string[],
    b2b: boolean,
    vouchers: VoucherData[],
    isForVZF: boolean,
): ProductTableCategoryProps => {
    const id = selectedProductCategory.id
    const productCategoryToReturn: ProductTableCategoryProps = {
        products: [],
        title: '',
    }

    const selectedProductCategoryFromData = getSelectedProductCategoryFromData(availableProductCategories, id)

    if (selectedProductCategoryFromData && selectedProductCategory.selectedProduct) {
        const selectedProductFromData: Product | undefined = selectedProductCategoryFromData.products.find(
            (product: Product) =>
                selectedProductCategory.selectedProduct && product.id === selectedProductCategory.selectedProduct.id,
        )
        if (selectedProductFromData) {
            productCategoryToReturn.title = selectedProductCategoryFromData.title
            const selectedProductFromDataPrice = b2b ? selectedProductFromData.priceNet : selectedProductFromData.gross
            let oldPrice: number | undefined
            let newPrice = selectedProductFromDataPrice
            let description: string[] | undefined
            if (selectedProductFromData.discounts.length > 0) {
                oldPrice = selectedProductFromDataPrice
                newPrice =
                    oldPrice - sumBy(selectedProductFromData.discounts, (d: Discount) => (b2b ? d.priceNet : d.gross))
                description = selectedProductFromData.discounts.map((discount) => discount.descriptionBasket)
            }

            if (
                !isForVZF &&
                selectedProductFromData.discounts.length === 0 &&
                selectedProductFromData.billMode == BillMode.RECURRING_MONTHLY
            ) {
                const calculation = caluclateUnlimitedVouchers(
                    vouchers,
                    processUniqueVoucherList,
                    selectedProductFromData.id,
                    newPrice,
                )
                newPrice = calculation.newPrice
                description = calculation.description
                oldPrice = calculation.oldPrice
            }

            productCategoryToReturn.products.push({
                category: selectedProductCategoryFromData.identifier,
                description: selectedProductFromData.titleBasket,
                value: {
                    oldPrice,
                    newPrice,
                    description,
                    billMode: selectedProductFromData.billMode,
                    info: !!selectedProductFromData.information
                        ? { title: selectedProductFromData.information }
                        : undefined,
                },
            })

            // Add voucher if any
            if (!isForVZF) {
                const associatedVoucher = getAssociatedMonthOneTimeVouchers(
                    vouchers,
                    processUniqueVoucherList,
                    selectedProductFromData.id,
                )
                associatedVoucher.forEach((voucher) => {
                    productCategoryToReturn.products.push({
                        category: 'priceComponents',
                        description: voucher.name,
                        value: {
                            billMode: BillMode.VOUCHER,
                            newPrice: voucher.value.value,
                            info: voucher.infoText.trim().length > 0 ? { title: voucher.infoText } : undefined,
                            description: [voucher.value.discountType === 'oneTime' ? 'ONE_TIME' : 'VOUCHER_MONTHLY'],
                            voucherData: {
                                oneTime: voucher.value.discountType === 'oneTime',
                                currency: voucher.value.currency,
                                month: voucher.value.month,
                            },
                        },
                    })
                })
            }

            for (const fee of selectedProductFromData.fees) {
                const invoiceSend = configuration.get('invoiceSend')
                const showInvoiceSendFee =
                    !isForVZF &&
                    customizeJsData &&
                    customizeJsData?.bankDetails.useInvoiceFee &&
                    customizeJsData?.bankDetails.useInvoiceFee.feeId === fee.id &&
                    ((customizeJsData?.bankDetails.useInvoiceFee.visibleB2B === true && b2b === true) ||
                        b2b === false) &&
                    invoiceSend &&
                    invoiceSend === 'viaMail'

                const showTransferFee =
                    !isForVZF &&
                    customizeJsData &&
                    customizeJsData?.bankDetails.useTransferFee &&
                    customizeJsData?.bankDetails.useTransferFee.feeId === fee.id &&
                    ((customizeJsData?.bankDetails.useTransferFee.visibleB2B === true && b2b === true) ||
                        b2b === false) &&
                    bankDetailsState.accountType !== AccountType.IBAN

                if (
                    (fee.processUnique && processUniqueFeeList.indexOf(fee.id) != -1) ||
                    (fee.optional && !showInvoiceSendFee && !showTransferFee)
                ) {
                    continue
                } else if (fee.processUnique) {
                    processUniqueFeeList.push(fee.id)
                }
                const feePrice = b2b ? fee.priceNet : fee.gross
                let oldPrice: number | undefined
                let newPrice = feePrice
                let description: string[] | undefined
                if (fee.discounts.length > 0) {
                    oldPrice = feePrice
                    newPrice = oldPrice - sumBy(fee.discounts, (d: Discount) => (b2b ? d.priceNet : d.gross))
                    description = fee.discounts.map((discount) => discount.descriptionBasket)
                }

                productCategoryToReturn.products.push({
                    category: 'priceComponents',
                    description: fee.titleBasket,
                    value: {
                        oldPrice,
                        newPrice,
                        description,
                        billMode: fee.billMode,
                    },
                })
            }

            selectedProductCategory.selectedProduct.productTypes.forEach((productType) => {
                const matchingProductType = selectedProductFromData.productTypes.find((pt) => pt.id === productType.id)
                if (matchingProductType && matchingProductType.optional) {
                    const matchingOptionPrice = b2b ? matchingProductType.priceNet : matchingProductType.gross
                    let description: string[] | undefined
                    let oldPrice: number | undefined
                    let newPrice = matchingOptionPrice as number
                    if (matchingProductType.discounts.length > 0) {
                        oldPrice = matchingOptionPrice as number
                        newPrice =
                            oldPrice -
                            sumBy(matchingProductType.discounts, (d: Discount) => (b2b ? d.priceNet : d.gross))
                        description = matchingProductType.discounts.map((discount) => discount.descriptionBasket)
                    }

                    if (
                        !isForVZF &&
                        matchingProductType.discounts.length === 0 &&
                        selectedProductFromData.billMode == BillMode.RECURRING_MONTHLY
                    ) {
                        const calculation = caluclateUnlimitedVouchers(
                            vouchers,
                            processUniqueVoucherList,
                            matchingProductType.id,
                            newPrice,
                        )
                        newPrice = calculation.newPrice
                        description = calculation.description
                        oldPrice = calculation.oldPrice
                    }

                    productCategoryToReturn.products.push({
                        category: matchingProductType.identifier,
                        description: matchingProductType.title,
                        value: {
                            billMode: matchingProductType.billMode ? matchingProductType.billMode : BillMode.IGNORE,
                            oldPrice,
                            newPrice,
                            description,
                            info: matchingProductType.information
                                ? {
                                      title: matchingProductType.information,
                                  }
                                : undefined,
                        },
                    })

                    // Add voucher if any
                    if (!isForVZF) {
                        const associatedVoucher = getAssociatedMonthOneTimeVouchers(
                            vouchers,
                            processUniqueVoucherList,
                            matchingProductType.id,
                        )
                        associatedVoucher.forEach((voucher) => {
                            productCategoryToReturn.products.push({
                                category: 'priceComponents',
                                description: voucher.name,
                                value: {
                                    billMode: BillMode.VOUCHER,
                                    newPrice: voucher.value.value,
                                    info: voucher.infoText.trim().length > 0 ? { title: voucher.infoText } : undefined,
                                    description: [
                                        voucher.value.discountType === 'oneTime' ? 'ONE_TIME' : 'VOUCHER_MONTHLY',
                                    ],
                                    voucherData: {
                                        oneTime: voucher.value.discountType === 'oneTime',
                                        currency: voucher.value.currency,
                                        month: voucher.value.month,
                                    },
                                },
                            })
                        })
                    }
                }

                productType.optionCategories.forEach((selectedOptionCategory: SelectedOptionCategory) => {
                    selectedProductFromData.productTypes.forEach((productType) => {
                        const matchingOptionCategory = productType.category.find(
                            (optionCategoryFromData: OptionCategory) =>
                                optionCategoryFromData.id === selectedOptionCategory.id,
                        )
                        if (matchingOptionCategory) {
                            selectedOptionCategory.selectedOptions.forEach((optionId: string) => {
                                const matchingOption = matchingOptionCategory.options.find(
                                    (option: Option) => option.id === optionId,
                                )
                                if (matchingOption) {
                                    const matchingOptionPrice = b2b ? matchingOption.priceNet : matchingOption.gross
                                    let oldPrice: number | undefined
                                    let newPrice = matchingOptionPrice
                                    let description: string[] | undefined
                                    if (matchingOption.discounts.length > 0) {
                                        oldPrice = matchingOptionPrice
                                        newPrice =
                                            oldPrice -
                                            sumBy(matchingOption.discounts, (d: Discount) =>
                                                b2b ? d.priceNet : d.gross,
                                            )
                                        description = matchingOption.discounts.map(
                                            (discount) => discount.descriptionBasket,
                                        )
                                    }

                                    let optionsMultipleSelectCounterLabel = ''
                                    const optionsMultipleSelectCounter = optionsMultipleSelect.get(matchingOption.id)
                                    if (optionsMultipleSelectCounter && optionsMultipleSelectCounter > 1) {
                                        optionsMultipleSelectCounterLabel = optionsMultipleSelectCounter + ' x '
                                    }

                                    const category = matchingOption.isHardware ? 'Hardware' : 'Service'
                                    productCategoryToReturn.products.push({
                                        category: productType.identifier + category,
                                        description: optionsMultipleSelectCounterLabel + matchingOption.titleBasket,
                                        value: {
                                            oldPrice,
                                            billMode: matchingOption.billMode,
                                            newPrice,
                                            multiple: optionsMultipleSelectCounter ? optionsMultipleSelectCounter : 0,
                                            description,
                                            info: !!matchingOption.information
                                                ? { title: matchingOption.information }
                                                : undefined,
                                        },
                                    })

                                    for (const fee of matchingOption.fees) {
                                        if (
                                            (fee.processUnique && processUniqueFeeList.indexOf(fee.id) != -1) ||
                                            fee.optional
                                        ) {
                                            continue
                                        } else if (fee.processUnique) {
                                            processUniqueFeeList.push(fee.id)
                                        }
                                        const matchingOptionFeePrice = b2b ? fee.priceNet : fee.gross
                                        let oldPrice: number | undefined
                                        let newPrice = matchingOptionFeePrice
                                        let description: string[] | undefined
                                        if (fee.discounts.length > 0) {
                                            oldPrice = matchingOptionFeePrice
                                            newPrice =
                                                oldPrice -
                                                sumBy(fee.discounts, (d: Discount) => (b2b ? d.priceNet : d.gross))
                                            description = fee.discounts.map((discount) => discount.descriptionBasket)
                                        }

                                        if (
                                            !isForVZF &&
                                            matchingOption.discounts.length === 0 &&
                                            selectedProductFromData.billMode == BillMode.RECURRING_MONTHLY
                                        ) {
                                            const calculation = caluclateUnlimitedVouchers(
                                                vouchers,
                                                processUniqueVoucherList,
                                                matchingOption.id,
                                                newPrice,
                                            )
                                            newPrice = calculation.newPrice
                                            description = calculation.description
                                            oldPrice = calculation.oldPrice
                                        }

                                        productCategoryToReturn.products.push({
                                            category: 'priceComponents',
                                            description: fee.titleBasket,
                                            value: {
                                                billMode: fee.billMode,
                                                oldPrice,
                                                newPrice,
                                                description,
                                            },
                                        })
                                    }

                                    if (!isForVZF) {
                                        const associatedVoucher = getAssociatedMonthOneTimeVouchers(
                                            vouchers,
                                            processUniqueVoucherList,
                                            matchingOption.id,
                                        )
                                        associatedVoucher.forEach((voucher) => {
                                            productCategoryToReturn.products.push({
                                                category: 'priceComponents',
                                                description: voucher.name,
                                                value: {
                                                    billMode: BillMode.VOUCHER,
                                                    newPrice: voucher.value.value,
                                                    info:
                                                        voucher.infoText.trim().length > 0
                                                            ? { title: voucher.infoText }
                                                            : undefined,
                                                    description: [
                                                        voucher.value.discountType === 'oneTime'
                                                            ? 'ONE_TIME'
                                                            : 'VOUCHER_MONTHLY',
                                                    ],
                                                    voucherData: {
                                                        oneTime: voucher.value.discountType === 'oneTime',
                                                        currency: voucher.value.currency,
                                                        month: voucher.value.month,
                                                    },
                                                },
                                            })
                                        })
                                    }
                                }
                            })
                        }
                    })
                })
            })
        }
    }
    return productCategoryToReturn
}

const calculateBasketCosts = (
    customizeJsData: Customize | undefined,
    bankDetailsState: BankDetailsState,
    optionsMultipleSelect: Map<string, number>,
    configuration: Map<string, string | string[]>,
    availableProductCategories: ProductCategory[],
    selectedProductCategory: SelectedProductCategory[],
    installationDetails: InstallationDetailsState,
    b2b: boolean,
    isForVZF: boolean,
    vouchers: VoucherData[],
): CostsTableProps | undefined => {
    const costTableToReturn: CostsTableProps = {
        monthlyCost: 0,
        monthlyIncrements: [],
        oneTimeCost: 0,
        oneTimeItems: [],
        totalDiscounts: {
            sum: 0,
            discounts: [],
        },
    }
    let monthlyIncrementsMap: MonthlyPaymentsData[] = []
    const processUniqueFeeList: string[] = []
    const processUniqueVoucherList: string[] = []
    let unlimitedVoucherCost = 0

    //going though the selected categories, product and options to calculate the price
    selectedProductCategory.forEach((selectedProductCategory: SelectedProductCategory) => {
        const id = selectedProductCategory.id
        const selectedProductCategoryFromData = getSelectedProductCategoryFromData(availableProductCategories, id)

        if (selectedProductCategoryFromData && selectedProductCategory.selectedProduct) {
            //find the selected product data
            const selectedProductFromData = selectedProductCategoryFromData.products.find(
                (product: Product) =>
                    selectedProductCategory.selectedProduct &&
                    product.id === selectedProductCategory.selectedProduct.id,
            )
            if (selectedProductFromData) {
                //validate if we need the netto price or the gross price
                let selectedProductFromDataPrice = b2b
                    ? selectedProductFromData.priceNet
                    : selectedProductFromData.gross

                if (selectedProductFromData.billMode === BillMode.RECURRING_MONTHLY) {
                    const calculationNewPrice = caluclateUnlimitedVouchers(
                        vouchers,
                        processUniqueVoucherList,
                        selectedProductFromData.id,
                        selectedProductFromDataPrice,
                    )
                    unlimitedVoucherCost += selectedProductFromDataPrice - calculationNewPrice.newPrice
                    selectedProductFromDataPrice = calculationNewPrice.newPrice
                }

                const associatedVoucher = getAssociatedMonthOneTimeVouchers(
                    vouchers,
                    processUniqueVoucherList,
                    selectedProductFromData.id,
                )

                caluclatePriceForBasket(
                    selectedProductFromData.title,
                    selectedProductFromDataPrice,
                    selectedProductFromData.billMode,
                    selectedProductFromData.discounts,
                    monthlyIncrementsMap,
                    costTableToReturn,
                    associatedVoucher,
                    b2b,
                    1,
                )

                //add the calculation of the fee's
                selectedProductFromData.fees.forEach((fee) => {
                    const invoiceSend = configuration.get('invoiceSend')
                    const showInvoiceSendFee =
                        !isForVZF &&
                        customizeJsData &&
                        customizeJsData?.bankDetails.useInvoiceFee &&
                        customizeJsData?.bankDetails.useInvoiceFee.feeId === fee.id &&
                        ((customizeJsData?.bankDetails.useInvoiceFee.visibleB2B === true && b2b === true) ||
                            b2b === false) &&
                        invoiceSend &&
                        invoiceSend === 'viaMail'
                    const showTransferFee =
                        !isForVZF &&
                        customizeJsData &&
                        customizeJsData?.bankDetails.useTransferFee &&
                        customizeJsData?.bankDetails.useTransferFee.feeId === fee.id &&
                        ((customizeJsData?.bankDetails.useTransferFee.visibleB2B === true && b2b === true) ||
                            b2b === false) &&
                        bankDetailsState.accountType !== AccountType.IBAN
                    if (
                        (fee.processUnique && processUniqueFeeList.indexOf(fee.id) != -1) ||
                        (fee.optional && !showInvoiceSendFee && !showTransferFee)
                    ) {
                        return
                    } else if (fee.processUnique) {
                        processUniqueFeeList.push(fee.id)
                    }

                    const selectedProductFromDataFeePrice = b2b ? fee.priceNet : fee.gross

                    caluclatePriceForBasket(
                        fee.title,
                        selectedProductFromDataFeePrice,
                        fee.billMode,
                        fee.discounts,
                        monthlyIncrementsMap,
                        costTableToReturn,
                        [],
                        b2b,
                        1,
                    )
                })

                // OPTION CATEGORIES
                selectedProductCategory.selectedProduct.productTypes.forEach((productType) => {
                    const matchingProductType = selectedProductFromData.productTypes.find(
                        (pt) => pt.id === productType.id,
                    )
                    if (matchingProductType && matchingProductType.optional) {
                        let matchingProductTypePrice = b2b ? matchingProductType.priceNet : matchingProductType.gross

                        if (!matchingProductTypePrice || !matchingProductType.billMode) return

                        if (matchingProductType.billMode === BillMode.RECURRING_MONTHLY) {
                            const calculationNewPrice = caluclateUnlimitedVouchers(
                                vouchers,
                                processUniqueVoucherList,
                                selectedProductFromData.id,
                                matchingProductTypePrice,
                            )
                            unlimitedVoucherCost += matchingProductTypePrice - calculationNewPrice.newPrice
                            matchingProductTypePrice = calculationNewPrice.newPrice
                        }

                        const associatedVoucher = getAssociatedMonthOneTimeVouchers(
                            vouchers,
                            processUniqueVoucherList,
                            matchingProductType.id,
                        )

                        caluclatePriceForBasket(
                            matchingProductType.title,
                            matchingProductTypePrice,
                            matchingProductType.billMode,
                            matchingProductType.discounts,
                            monthlyIncrementsMap,
                            costTableToReturn,
                            associatedVoucher,
                            b2b,
                            1,
                        )
                    }

                    productType.optionCategories.forEach((selectedOptionCategory: SelectedOptionCategory) => {
                        selectedProductFromData.productTypes.forEach((productType) => {
                            const matchingOptionCategory = productType.category.find(
                                (optionCategoryFromData: OptionCategory) =>
                                    optionCategoryFromData.id === selectedOptionCategory.id,
                            )
                            if (matchingOptionCategory) {
                                selectedOptionCategory.selectedOptions.forEach((optionId: string) => {
                                    const matchingOption = matchingOptionCategory.options.find(
                                        (option: Option) => option.id === optionId,
                                    )
                                    if (matchingOption) {
                                        let matchingOptionPrice = b2b ? matchingOption.priceNet : matchingOption.gross
                                        const optionsMultipleSelectCounter =
                                            optionsMultipleSelect.get(matchingOption.id) ?? 1

                                        if (matchingOption.billMode === BillMode.RECURRING_MONTHLY) {
                                            const calculationNewPrice = caluclateUnlimitedVouchers(
                                                vouchers,
                                                processUniqueVoucherList,
                                                selectedProductFromData.id,
                                                matchingOptionPrice,
                                            )
                                            unlimitedVoucherCost += matchingOptionPrice - calculationNewPrice.newPrice
                                            matchingOptionPrice = calculationNewPrice.newPrice
                                        }

                                        const associatedVoucher = getAssociatedMonthOneTimeVouchers(
                                            vouchers,
                                            processUniqueVoucherList,
                                            matchingOption.id,
                                        )

                                        caluclatePriceForBasket(
                                            matchingOption.title,
                                            matchingOptionPrice,
                                            matchingOption.billMode,
                                            matchingOption.discounts,
                                            monthlyIncrementsMap,
                                            costTableToReturn,
                                            associatedVoucher,
                                            b2b,
                                            optionsMultipleSelectCounter,
                                        )

                                        matchingOption.fees.forEach((fee) => {
                                            if (
                                                (fee.processUnique && processUniqueFeeList.indexOf(fee.id) != -1) ||
                                                fee.optional
                                            ) {
                                                return
                                            } else if (fee.processUnique) {
                                                processUniqueFeeList.push(fee.id)
                                            }
                                            const feePrice = b2b ? fee.priceNet : fee.gross

                                            caluclatePriceForBasket(
                                                fee.title,
                                                feePrice,
                                                fee.billMode,
                                                fee.discounts,
                                                monthlyIncrementsMap,
                                                costTableToReturn,
                                                [],
                                                b2b,
                                                1,
                                            )
                                        })
                                    }
                                })
                            }
                        })
                    })
                })
            }
        }
    })

    // One time cost installation service
    if (installationDetails.installationService !== 0) {
        costTableToReturn.oneTimeCost += installationDetails.installationService
        costTableToReturn.oneTimeItems.push({
            title: 'installationDetailsStrings.installationService.Title',
            value: {
                billMode: BillMode.IGNORE,
                newPrice: installationDetails.installationService,
            },
        })
    }

    // Monthly Increments for Products
    // 49.99
    /**
     * - Products, discounts, fees, discounts
     *    Option, discounts, fees, discounts
     * 0 - 30 = 19.99 <- BIG MONTHLY PRICE
     * 1 - 30 = 19.99
     * 2 - 20 = 29.99 <- after 2 month 29.99
     * 3 - 20 = 29.99
     *                <- after 4 month 49.99
     */

    // check if it has monthly increaments
    if (monthlyIncrementsMap.length > 0) {
        // sort to get the max month
        monthlyIncrementsMap = monthlyIncrementsMap.sort((a, b) => (a.endMonth > b.endMonth ? -1 : 1))
        const maxMonth = monthlyIncrementsMap[0].endMonth + 1
        let maxPrice = costTableToReturn.monthlyCost

        // iterate through the month
        for (let currentMonth = 1; currentMonth <= maxMonth; currentMonth++) {
            let monthPrice = 0
            // iterate through the monthly increaments and calculate the price
            monthlyIncrementsMap.forEach((monthlyIncrement) => {
                if (
                    (monthlyIncrement.startMonth === -1 || currentMonth > monthlyIncrement.startMonth) &&
                    (monthlyIncrement.endMonth === -1 || currentMonth <= monthlyIncrement.endMonth)
                ) {
                    monthPrice += monthlyIncrement.value
                }
            })

            // check if the price has changed and add it to the array
            if (maxPrice !== monthPrice) {
                costTableToReturn.monthlyIncrements.push({
                    title: String(currentMonth),
                    value: {
                        billMode: BillMode.IGNORE,
                        newPrice: monthPrice,
                    },
                })
                maxPrice = monthPrice
            }
        }
    }

    if (costTableToReturn.oneTimeCost < 0) {
        costTableToReturn.oneTimeCost = 0
    }
    if (costTableToReturn.monthlyCost < 0) {
        costTableToReturn.monthlyCost = 0
    }

    if (unlimitedVoucherCost > 0) {
        costTableToReturn.monthlyOldCost = costTableToReturn.monthlyCost + unlimitedVoucherCost
        costTableToReturn.unlimitedVoucherCost = unlimitedVoucherCost
    }

    return costTableToReturn
}

export const basketCalculation = (
    availabilityCheckState: AvailabilityCheckState,
    bankDetailsState: BankDetailsState,
    generalState: GeneralState,
    b2b: boolean,
    isForVZF: boolean,
): BasketProps => {
    const productCategories: ProductTableCategoryProps[] = []
    const processUniqueFeeList: string[] = []
    const processUniqueVoucherList: string[] = []
    const customizeJsData = generalState.customizeJsData

    generalState.selectedProductCategories.forEach((selectedProductCategory: SelectedProductCategory) => {
        const formattedProductCategory = formatSelectedProductCategory(
            customizeJsData,
            bankDetailsState,
            generalState.optionsMultipleSelect,
            generalState.configuration,
            generalState.availableProductCategories,
            selectedProductCategory,
            processUniqueFeeList,
            processUniqueVoucherList,
            b2b,
            generalState.voucher,
            isForVZF,
        )
        if (!_.isEqual(formattedProductCategory, { title: '', products: [] })) {
            productCategories.push(formattedProductCategory)
        }
    })

    const costs = calculateBasketCosts(
        customizeJsData,
        bankDetailsState,
        generalState.optionsMultipleSelect,
        generalState.configuration,
        generalState.availableProductCategories,
        generalState.selectedProductCategories,
        generalState.installationDetails,
        b2b,
        isForVZF,
        generalState.voucher,
    )

    return {
        products: {
            address: {
                city: availabilityCheckState.selectedCity,
                houseNumber: availabilityCheckState.selectedHouseNumber,
                street: availabilityCheckState.selectedStreet,
                zip: availabilityCheckState.zip,
                addition: availabilityCheckState.selectedAddition,
                district: availabilityCheckState.selectedDistrict,
            },
            productCategories,
        },
        costs,
    }
}
