import { format } from 'date-fns'
import {
    BankDetailsData,
    BillMode,
    ConfigurationItem,
    ContractData,
    ExtraPersonalData,
    MultipleSelectOptionItem,
    OrderCategoryInput,
    OrderDataInput,
    OrderProductDetailsInput,
    PersonalData,
    PortabilityData,
} from 'graphql/types'
import { ViewType } from 'store/GeneralState/GeneralState.reducer'
import { AppState } from 'store/store'
import { pathToViewType } from 'utils/testable/pathToViewType'
import { VoucherDataInput } from './../graphql/types'
import { URLParams } from './URLParamsContex'
import { basketCalculation } from './testable/basketCalculation'
import { toCRMDate } from './testable/toCRMDate'

export const convertStateToOrderData = (state: AppState, B2B: boolean): OrderDataInput => {
    const configurationEntries: ConfigurationItem[] = []
    for (const [key, value] of state.generalState.configuration.entries()) {
        configurationEntries.push({ key, value: typeof value !== 'string' ? value.join(',') : value })
    }
    const multipleSelectOptionList: MultipleSelectOptionItem[] = []
    for (const [key, value] of state.generalState.optionsMultipleSelect.entries()) {
        multipleSelectOptionList.push({ key, value })
    }
    let birthDateCRM = ''
    if (state.contactData.personalBirthDate !== '') {
        birthDateCRM = toCRMDate(state.contactData.personalBirthDate)
    }

    const landLineContractOptionsExit =
        state.generalState.pagesList.findIndex((l) => pathToViewType(l.path) === ViewType.LANDLINE_CONTRACT_OPTIONS) !==
        -1

    const personalAddress: ExtraPersonalData = {
        useSalesPartnerEmail: state.contactData.useSalesPartnerEmail,
        zipcode: state.availabilityCheck.zip,
        city: state.availabilityCheck.selectedCity,
        street: state.availabilityCheck.selectedStreet,
        houseNumber: state.availabilityCheck.selectedHouseNumber,
        addition: state.availabilityCheck.selectedAddition,
        district: state.availabilityCheck.selectedDistrict,
        additionalInfo: state.contactData.personalAdditionalAddressInfo ?? '',
        salutation: state.contactData.personalSalutation,
        title: state.contactData.personalTitle,
        name: state.contactData.personalName,
        lastName: state.contactData.personalLastName,
        email: state.contactData.personalEmail,
        birthDate: birthDateCRM,
        telephone: state.contactData.personalTelephone,
        mobilePhone: state.contactData.personalMobilePhone,
        company: state.contactData.company,
        companyLegalForm: state.contactData.companyLegalForm,
        companyRegisterEntry: state.contactData.companyRegisterEntry,
        companyLocation: state.contactData.companyLocation,
        companyId: state.contactData.companyId,
    }

    const deliveryAddress: PersonalData = {
        zipcode: state.contactData.deliveryZip,
        company: state.contactData.deliveryCompany,
        companyLegalForm: state.contactData.deliveryCompanyLegalForm,
        city: state.contactData.deliveryCity,
        street: state.contactData.deliveryStreet,
        houseNumber: state.contactData.deliveryHouseNumber,
        additionalInfo: state.contactData.deliveryAdditionalAddrInfo ?? '',
        country: state.contactData.deliveryCountryAddr ?? '',
        salutation: state.contactData.deliverySalutation,
        title: state.contactData.deliveryTitle,
        name: state.contactData.deliveryName,
        lastName: state.contactData.deliveryLastName,
    }

    const billingAddress: PersonalData = {
        zipcode: state.contactData.billingZip,
        city: state.contactData.billingCity,
        street: state.contactData.billingStreet,
        houseNumber: state.contactData.billingHouseNumber,
        additionalInfo: state.contactData.billingAdditionalAddrInfo ?? '',
        country: state.contactData.billingCountryAddr ?? '',
        salutation: state.contactData.billingSalutation,
        title: state.contactData.billingTitle,
        name: state.contactData.billingName,
        lastName: state.contactData.billingLastName,
        company: state.contactData.billingCompany,
        companyLegalForm: state.contactData.billingCompanyLegalForm,
    }

    const bankDetails: BankDetailsData = {
        accountType: state.bankDetails.accountType,
        differentAccountHolder: state.bankDetails.differentAccountHolder,
        consentChecked: state.bankDetails.consentChecked,
        accountHolderData: {
            zipcode: state.bankDetails.accountHolderData.zip,
            city: state.bankDetails.accountHolderData.city,
            street: state.bankDetails.accountHolderData.street,
            houseNumber: state.bankDetails.accountHolderData.houseNumber,
            additionalInfo: state.bankDetails.accountHolderData.additionalInfoAddress ?? '',
            country: state.bankDetails.accountHolderData.countryAddress ?? '',
            salutation: state.bankDetails.accountHolderData.salutation,
            title: state.bankDetails.accountHolderData.title,
            name: state.bankDetails.accountHolderData.name,
            lastName: state.bankDetails.accountHolderData.lastName,
            company: state.bankDetails.accountHolderData.company,
            companyLegalForm: state.bankDetails.accountHolderData.companyLegalForm,
        },
        iban: state.bankDetails.iban,
        transfer: state.bankDetails.transfer,
        dayOfTransfer:
            state.generalState.customizeJsData && state.generalState.customizeJsData.bankDetails.displayDayOfTransfer
                ? state.bankDetails.dayOfTransfer
                : '',
    }

    const portability: PortabilityData = {
        address: {
            city: state.portabilityState.address.city,
            houseNumber: state.portabilityState.address.houseNumber,
            street: state.portabilityState.address.street,
            zipcode: state.portabilityState.address.zip,
        },
        contractHolderOptions: state.portabilityState.contractHolderOptions,
        endOfContract:
            state.portabilityState.endOfContract.length > 0 ? toCRMDate(state.portabilityState.endOfContract) : '',
        phoneOptions: state.portabilityState.phoneOptions,
        selectedProvider: state.portabilityState.selectedProvider,
        selectedRadios: state.portabilityState.selectedRadios,
        landLineContractOptionsExit: landLineContractOptionsExit,
    }

    const contractData: ContractData = {
        desiredDate: format(state.generalState.desiredDate ?? new Date(), 'yyyy-MM-dd'),
        earliestDatePossible: state.generalState.earliestDatePossible,
        installationDetails: state.generalState.installationDetails,
        configuration: configurationEntries,
        selectedProductCategories: state.generalState.selectedProductCategories,
        customerNumber: state.contactData.existingCustomerNumber,
        hasCustomerNumber: state.contactData.existingCustomerRadio === 'yes',
        startOfContract: state.generalState.startOfContract ? state.generalState.startOfContract.getTime() : 0,
    }

    //TODO: implement configuration for not setting orderProductDetails
    contractData.orderProductDetails = resolveSelectedProducts(state, B2B)

    const voucherList: VoucherDataInput[] = []

    for (const voucher of state.generalState.voucher) {
        voucherList.push({
            code: voucher.code,
            id: voucher.id,
            type: voucher.type,
            name: voucher.name,
            currency: voucher.value.currency,
            discountType: voucher.value.discountType,
            month: voucher.value.month,
            value: voucher.value.value,
            infoText: voucher.infoText,
            articleNumber: voucher.articleNumber,
        })
    }

    const orderDataInput: OrderDataInput = {
        personalAddress,
        deviatingDeliveryAddress: state.contactData.deviatingDeliveryAddress,
        deviatingBillingAddress: state.contactData.deviatingBillingAddress,
        deliveryAddress,
        billingAddress,
        bankDetails,
        portability,
        contractData,
        opt: [],
        distributor: '',
        multipleSelectOptionList: multipleSelectOptionList,
        b2b: URLParams().B2B,
        vouchers: voucherList,
        vzfID: state.generalState.vzfID,
        customizeType: state.generalState.orderProcessType,
    }

    if (
        state.generalState.customizeJsData &&
        state.generalState.clientData &&
        state.generalState.customizeJsData.globalConfiguration.enableLoadingClient
    ) {
        orderDataInput.clientID = state.generalState.clientData.clientID
        orderDataInput.clientCompany = state.generalState.clientData.company
        orderDataInput.salesPartner = state.generalState.clientData.salesPartnerFullName
    }

    return JSON.parse(JSON.stringify(orderDataInput))
}

const resolveSelectedProducts = (state: AppState, B2B: boolean): OrderProductDetailsInput => {
    // get the basket calculated price
    const basketData = basketCalculation(state.availabilityCheck, state.bankDetails, state.generalState, B2B, false)

    //resolve the selected products
    const categories: OrderCategoryInput[] = []
    for (const category of state.generalState.selectedProductCategories) {
        //find selected category data
        const categoryData = state.generalState.availableProductCategories.find((c) => c.id === category.id)

        if (!categoryData) {
            continue
        }

        const productData = categoryData.products.find((p) => p.id === category.selectedProduct?.id)
        if (!productData) {
            continue
        }

        const fees = productData?.fees.map((fee) => ({
            id: fee.id,
            title: fee.title,
            billMode: fee.billMode,
            gross: fee.gross,
            priceNet: fee.priceNet,
            priceVat: fee.priceVat,
            discounts: fee.discounts.map((discount) => ({
                id: discount.id,
                title: discount.title,
                billMode: discount.billMode,
                gross: discount.gross,
                priceNet: discount.priceNet,
                priceVat: discount.priceVat,
                discountLength: discount.discountLength,
            })),
        }))

        const discount = productData?.discounts.map((discount) => ({
            id: discount.id,
            title: discount.title,
            billMode: discount.billMode,
            gross: discount.gross,
            priceNet: discount.priceNet,
            priceVat: discount.priceVat,
            discountLength: discount.discountLength,
        }))

        categories.push({
            id: category.id,
            description: categoryData.description ?? '',
            identifier: categoryData.identifier,
            title: categoryData.title,
            products: {
                id: productData?.id ?? '',
                title: productData?.title ?? '',
                description: productData?.description ?? '',
                productInfoSheet: productData?.dataSheet ?? '',
                subCategory: productData?.subCategory ?? '',
                billMode: productData?.billMode ?? BillMode.RECURRING_MONTHLY,
                priceGross: productData?.gross ?? 0,
                priceNet: productData?.priceNet ?? 0,
                priceVat: productData?.priceVat ?? 0,
                information: productData?.information ?? '',
                minimumContractPeriod: productData?.minimumContractPeriod ?? '',
                internalID: '',
                internalName: '',
                groupBy: '',
                upload: productData?.productTypes[0].upload ?? '',
                uploadStandard: productData?.productTypes[0].uploadStandard ?? '',
                uploadMinimal: productData?.productTypes[0].uploadMinimal ?? '',
                download: productData?.productTypes[0].download ?? '',
                downloadStandard: productData?.productTypes[0].downloadStandard ?? '',
                downloadMinimal: productData?.productTypes[0].downloadStandard ?? '',
                fees: fees,
                discounts: discount,
            },
        })
    }

    return {
        categories,
        monthlyPrice: basketData.costs?.monthlyCost ?? 0,
        oneTimePrice: basketData.costs?.oneTimeCost ?? 0,
    }
}
