import { useLazyQuery } from '@apollo/client'
import * as DateFns from 'date-fns'
import LoadCATVData from 'graphql/queries/LoadCATVData'
import { AccountType, CatvCustomerDataResponse, ProductCategory, Query } from 'graphql/types'
import { useCallback, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Dispatch } from 'redux'
import { PersistState } from 'redux-persist'
import AvailabilityCheckActions, {
    AvailabilityCheckAction,
    AvailabilityCheckField,
} from 'store/AvailabilityCheck/AvailabilityCheck.actions'
import { AvailabilityCheckState } from 'store/AvailabilityCheck/AvailabilityCheck.reducer'
import BankDetailsActions, { BankDetailsAction } from 'store/BankDetails/BankDetails.actions'
import { BankDetailsState } from 'store/BankDetails/BankDetails.reducer'
import ContactDataActions, { ContactDataAction } from 'store/ContactData/ContactData.actions'
import { ContactDataState } from 'store/ContactData/ContactData.reducer'
import GeneralStateActions, { GeneralStateAction } from 'store/GeneralState/GeneralState.actions'
import { GeneralState, LoadState, ViewType, initialGeneralState } from 'store/GeneralState/GeneralState.reducer'
import PortabilityStateActions, { PortabilityStateAction } from 'store/PortabilityState/PortabilityState.actions'
import { AppState } from 'store/store'
import useURLParams from 'utils/URLParamsContex'
import { CustomerDataLoadProps } from './CustomerDataLoad'

interface CustomerDataLoadReturn {
    loadState: LoadState
    error: string
    onClickReturn: () => void
}

const useCustomerDataLoad: (props: CustomerDataLoadProps) => CustomerDataLoadReturn = (
    props: CustomerDataLoadProps,
) => {
    const { data } = props
    const history = useHistory()
    const { B2B } = useURLParams()
    const dispatch =
        useDispatch<
            Dispatch<
                | AvailabilityCheckActions
                | BankDetailsActions
                | ContactDataActions
                | GeneralStateActions
                | PortabilityStateActions
            >
        >()
    const { loadState, customizeJsData, pagesList, currentPage } = useSelector((appState: AppState) => ({
        loadState: appState.generalState.loadState,
        customizeJsData: appState.generalState.customizeJsData,
        pagesList: appState.generalState.pagesList,
        currentPage: appState.generalState.currentPage,
    }))

    const availabilityDispatch = useDispatch<Dispatch<AvailabilityCheckActions>>()

    const resetState = useCallback(() => {
        dispatch({ type: AvailabilityCheckAction.SET_TO_INIT_STATE, payload: null })
        dispatch({ type: ContactDataAction.SET_TO_INIT_STATE })
        dispatch({ type: BankDetailsAction.SET_TO_INIT_STATE, payload: null })
        dispatch({ type: GeneralStateAction.SET_TO_INIT_STATE, payload: { B2B } })
        dispatch({ type: PortabilityStateAction.SET_TO_INIT_STATE, payload: null })
    }, [dispatch])

    const [errorMessage, setErrorMessage] = useState('')

    const setZip = useCallback(
        (zip: string) => {
            availabilityDispatch({ type: AvailabilityCheckAction.SET_ZIP, payload: zip })
        },
        [availabilityDispatch],
    )

    const setFocus = useCallback(
        (focusId: AvailabilityCheckField) => {
            availabilityDispatch({ type: AvailabilityCheckAction.SET_FOCUSED_FIELD, payload: focusId })
        },
        [availabilityDispatch],
    )

    const setLoadState = useCallback(
        (payload: LoadState): void => {
            dispatch({ type: GeneralStateAction.SET_LOAD_STATE, payload })
        },
        [dispatch],
    )

    const setOrderProcessType = useCallback(
        (payload: string) => {
            dispatch({ type: GeneralStateAction.SET_ORDER_PROCESS_TYPE, payload })
        },
        [dispatch],
    )

    const setAvailabilityCheck = useCallback(
        (payload: AvailabilityCheckState): void => {
            dispatch({ type: AvailabilityCheckAction.SET_AVAILABILITY_STATE, payload })
        },
        [dispatch],
    )

    const setGeneralState = useCallback(
        (payload: GeneralState): void => {
            dispatch({ type: GeneralStateAction.SET_GENERAL_STATE, payload })
        },
        [dispatch],
    )

    const updatePageList = useCallback(
        (payload: { B2B: boolean }): void => {
            dispatch({ type: GeneralStateAction.UPDATE_PAGELIST, payload })
        },
        [dispatch],
    )

    const setContactDataState = useCallback(
        (payload: ContactDataState): void => {
            dispatch({ type: ContactDataAction.SET_CONTACT_DATA_STATE_PARTIAL, payload })
        },
        [dispatch],
    )

    const setBankDetailsState = useCallback(
        (payload: BankDetailsState): void => {
            dispatch({ type: BankDetailsAction.SET_BANK_DETAILS_STATE, payload })
        },
        [dispatch],
    )

    const [loadCATVData] = useLazyQuery<Query>(LoadCATVData, {
        fetchPolicy: 'network-only',
        onCompleted: (data: { loadCATVData: CatvCustomerDataResponse }): void => {
            const CATVData = data.loadCATVData.data
            const productCategories = data.loadCATVData.productCategories
            if (data.loadCATVData.status == 'success' && CATVData && productCategories) {
                const contactData: ContactDataState = {
                    personalSalutation: CATVData.Salutation,
                    personalTitle: CATVData.Title,
                    personalName: CATVData.FirstName,
                    personalLastName: CATVData.LastName,
                    personalEmail: CATVData.Email,
                    personalBirthDate: DateFns.format(
                        DateFns.parse(CATVData.Birthdate, 'yyyy-MM-dd', new Date()),
                        'dd.MM.yyyy',
                    ),
                    personalTelephone: CATVData.Telephone,
                    personalMobilePhone: '',
                    personalAdditionalAddressInfo: undefined,
                    useSalesPartnerEmail: false,

                    deviatingDeliveryAddress: false,
                    deliverySalutation: '',
                    deliveryTitle: '',
                    deliveryName: '',
                    deliveryLastName: '',
                    deliveryStreet: '',
                    deliveryHouseNumber: '',
                    deliveryZip: '',
                    deliveryCity: '',
                    deliveryAdditionalAddrInfo: undefined,
                    deliveryCountryAddr: undefined,
                    deliveryCompany: '',
                    deliveryCompanyLegalForm: '',

                    deviatingBillingAddress: false,
                    billingSalutation: '',
                    billingTitle: '',
                    billingName: '',
                    billingLastName: '',
                    billingStreet: '',
                    billingHouseNumber: '',
                    billingZip: '',
                    billingCity: '',
                    billingAdditionalAddrInfo: undefined,
                    billingCountryAddr: undefined,
                    billingCompany: '',
                    billingCompanyLegalForm: '',

                    existingCustomerNumber: CATVData.CustomerID, //TODO: add verification that is a verfied customer
                    existingCustomerRadio: 'yes',

                    company: CATVData.Company,
                    companyLegalForm: CATVData.CompanyLegalForm,
                    companyRegisterEntry: '',
                    companyLocation: '',
                    companyId: '',

                    _persist: { rehydrated: false, version: 1 } as PersistState,
                }

                if (CATVData.BillingAddress) {
                    contactData.billingZip = CATVData.BillingAddress.ZipCode
                    contactData.billingCity = CATVData.BillingAddress.City
                    contactData.billingStreet = CATVData.BillingAddress.Street
                    contactData.billingHouseNumber = CATVData.BillingAddress.HouseNumber
                    contactData.billingSalutation = CATVData.BillingAddress.Salutation
                    contactData.billingTitle = CATVData.BillingAddress.Title
                    contactData.billingName = CATVData.BillingAddress.FirstName
                    contactData.billingLastName = CATVData.BillingAddress.LastName
                }

                const bankData: BankDetailsState = {
                    accountType: CATVData.BankData.IsTransfer ? AccountType.TRANSFER : AccountType.IBAN,
                    consentChecked: true,
                    consentCollectMoneyFromAccountChecked: true,
                    consentSEPAChecked: true,
                    differentAccountHolder: false,
                    accountHolderData: {
                        salutation: '',
                        title: '',
                        name: '',
                        lastName: '',
                        street: '',
                        houseNumber: '',
                        zip: '',
                        city: '',
                        company: '',
                        companyLegalForm: '',
                        additionalInfoAddress: undefined,
                        countryAddress: undefined,
                    },
                    iban: CATVData.BankData.Iban,
                    transfer: CATVData.BankData.IsTransfer,
                    isValidating: false,
                    dayOfTransfer: '15',
                    _persist: { rehydrated: false, version: 1 } as PersistState,
                }
                if (CATVData.AccountHolderAddress) {
                    bankData.differentAccountHolder = true
                    bankData.accountHolderData = {
                        salutation: CATVData.AccountHolderAddress.Salutation,
                        title: CATVData.AccountHolderAddress.Title,
                        name: CATVData.AccountHolderAddress.FirstName,
                        lastName: CATVData.AccountHolderAddress.LastName,
                        street: CATVData.AccountHolderAddress.Street,
                        houseNumber: CATVData.AccountHolderAddress.HouseNumber,
                        zip: CATVData.AccountHolderAddress.ZipCode,
                        city: CATVData.AccountHolderAddress.City,
                        company: '',
                        companyLegalForm: '',
                        additionalInfoAddress: '',
                        countryAddress: '',
                    }
                }

                setGeneralState({
                    ...initialGeneralState,
                    vzfID: '',
                    availableProductCategories: productCategories as ProductCategory[],
                    selectedProductCategories: [{ id: productCategories[0]?.id ?? '' }],
                    startOfDelivery: undefined,
                    startOfMarketing: undefined,
                    customizeJsData: customizeJsData,
                    currentView: ViewType.CUSTOMER_LOAD,
                    orderProcessType: 'catvkupo',
                })
                setAvailabilityCheck({
                    cities: [],
                    selectedCity: CATVData.ConnectionAddress.City,
                    focusedFieldId: AvailabilityCheckField.ZIP,
                    houseNumbers: [],
                    selectedHouseNumber: CATVData.ConnectionAddress.HouseNumber,
                    selectedStreet: CATVData?.ConnectionAddress.Street,
                    streets: [],
                    selectedDistrict: '',
                    districts: [],
                    selectedAddition: '',
                    additions: [],
                    zip: CATVData.ConnectionAddress.ZipCode,
                    vzf: '',
                    vzfNoResult: false,
                    _persist: { rehydrated: false, version: 1 } as PersistState,
                })
                setContactDataState(contactData)
                setBankDetailsState(bankData)
                setLoadState({
                    ...loadState,
                    loading: false,
                })
                updatePageList({ B2B })
                if (customizeJsData?.productCategoriesConfiguration.enablePage) {
                    history.push(pagesList[currentPage + 1].path)
                } else {
                    history.push('/Produktauswahl/' + productCategories[0]?.id)
                }
            } else {
                setErrorMessage(data.loadCATVData.message)
                setLoadState({
                    ...loadState,
                    loading: false,
                })
            }
        },
    })

    const onClickReturn = (): void => {
        if (
            customizeJsData?.globalConfiguration.logoClickWebsite &&
            customizeJsData?.globalConfiguration.logoClickWebsite !== undefined
        )
            window.open(customizeJsData?.globalConfiguration.logoClickWebsite, '_blank')
        else {
            setZip('')
            setFocus(AvailabilityCheckField.ZIP)
            resetState()
            history.push('/')
        }
    }

    useLayoutEffect(() => {
        setLoadState({
            loading: true,
        })
        setOrderProcessType('catvkupo')
    }, [])

    useLayoutEffect(() => {
        if (
            customizeJsData?.globalConfiguration.productGroups &&
            customizeJsData?.globalConfiguration.productGroups.length > 0
        ) {
            loadCATVData({
                variables: {
                    productGroups: customizeJsData?.globalConfiguration.productGroups,
                    data,
                },
            })
        }
    }, [customizeJsData])

    return {
        loadState,
        error: errorMessage,
        onClickReturn,
    }
}

export default useCustomerDataLoad
